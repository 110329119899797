import styled, { keyframes } from "styled-components";
import icon from "../../../../images/toggle-icon.svg";

const play72 = keyframes`
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -3600px 0px;
  }
`;
const play72Reverse = keyframes`
  0% {
    background-position: -3600px 0px;
  }
  100% {
    background-position: 0px 0px;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-color: transparent;
  background: transparent;
  cursor: pointer;
  /* reset firefox click effect */
  padding: 1px;
  color: white;
`;

export const Icon = styled.div`
  background: url(${icon});
  width: 50px;
  height: 50px;
  animation-duration: 1200ms;
  animation-timing-function: steps(72);
  animation-fill-mode: forwards;
  &.play {
    animation-name: ${props => (props.open ? play72 : play72Reverse)};
  }
`;
