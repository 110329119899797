/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";

import { ThemeProvider } from "styled-components";
import { theme } from "./themeStyles";

import Navigation from "./Navigation/Navigation";
import "./layout.css";
import { GlobalStyles, LayoutContainer } from "./globalStyles";
import { Container } from "./LayoutElements.styled";

import Footer from "./Footer/Footer";

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles></GlobalStyles>
        <LayoutContainer>
          <Navigation />
          <Container fullWidth inherit row="main" as="main">
            {children}
          </Container>
          <Footer></Footer>
        </LayoutContainer>
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
