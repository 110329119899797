import styled from "styled-components";

export const Container = styled.div`
  grid-column: ${(props) =>
    props.fullWidth ? "left-gutter-start/right-gutter-end" : "content"};
  grid-row: ${(props) => (props.row ? props.row : null)};
  display: ${(props) => (props.inherit ? "inherit" : "block")};
  grid-template-columns: ${(props) => (props.inherit ? "inherit" : null)};
  padding: ${(props) =>
    props.padding === true ? "0 2rem" : props.padding ? props.padding : null};
  justify-self: ${(props) => (props.center ? "center" : null)};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.theme.sectionSpacing : null};
  max-width: ${(props) => (props.textContainer ? "70ch" : null)};
`;
