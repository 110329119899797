import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *, *::after, *::before {
    box-sizing: border-box;
  }
  html, body {
    height: 100%;
  }
`;

export const LayoutContainer = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: ${props =>
    `[header] ${props.theme.headerNavHeight} [main] 1fr [footer] auto`};
  grid-template-columns: ${({ theme }) =>
    `[left-gutter-start] minmax(1rem,1fr) [left-gutter-end content-start] minmax(0px, ${theme.mainContainerWidth})
    [content-end right-gutter-start] minmax(1rem,1fr) [right-gutter-end]`};
`;
