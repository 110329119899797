import React from "react";
import { Container } from "../LayoutElements.styled";
import { Logo, NavContainer } from "./Navigation.styled";
import Menu from "./Menu/Menu";

const navLinks = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Contact",
    link: "/contact",
  },
];

const Navigation = () => (
  <Container row="header" as="header">
    <nav style={{ height: "100%" }}>
      <NavContainer>
        <li>
          <Logo to="/">Serbay</Logo>
        </li>
        <li>
          <Menu navLinks={navLinks}></Menu>
        </li>
      </NavContainer>
    </nav>
  </Container>
);

export default Navigation;
