import styled from "styled-components";
import { Container } from "../LayoutElements.styled";

export const FooterStyled = styled(Container)`
  height: ${(props) => props.theme.footerHeight};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  justify-content: center;
  & p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
  & .copyright {
    background: ${(props) => props.theme.primaryBg};
    color: white;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.75rem;
  }
`;
