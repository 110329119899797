import React, { useState } from "react";
import { NavLinkContainer, NavItem, Toggle } from "./Menu.styled";
import PropTypes from "prop-types";
import MenuToggle from "../MenuToggle/MenuToggle";
import Overlay from "../../../UI/Overlay/Overlay";

const menuId = "main-menu";

const Menu = ({ navLinks }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <NavLinkContainer open={open} id={menuId}>
        <Toggle>
          <MenuToggle
            open={open}
            setOpen={setOpen}
            aria-controls={menuId}
          ></MenuToggle>
        </Toggle>
        {navLinks.map((v, i) => (
          <li key={v.name} className="navMenu">
            <NavItem activeClassName="active" to={v.link}>
              {v.name}
            </NavItem>
          </li>
        ))}
      </NavLinkContainer>
      <Overlay open={open} setOpen={setOpen}></Overlay>
    </>
  );
};

Menu.propTypes = {
  navLinks: PropTypes.array.isRequired,
};

export default Menu;
