import styled from "styled-components";
import { Link } from "gatsby";

export const NavContainer = styled.ul`
  display: flex;
  padding: 0 2rem;
  height: 100%;
  & li {
    margin-bottom: 0;
  }
  & > li:nth-child(2) {
    margin-left: auto;
    margin-right: 6rem;
  }
  @media screen and (min-width: ${({ theme }) => theme.largeScreen}) {
    padding-left: 0;
  }
`;

export const Logo = styled(Link)`
  display: block;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 65px;
  /* identical to box height, or 232% */
  letter-spacing: -1.4px;
  text-transform: uppercase;
  background-image: linear-gradient(
    to right,
    #646fea 0%,
    #4992ec 40.31%,
    #4db4f2 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding-right: 1rem;
`;
