import React from "react";
import PropTypes from "prop-types";
import { OverlayStyled } from "./Overlay.styled";

const Overlay = ({ open, setOpen }) => {
  return (
    <OverlayStyled open={open} onClick={() => setOpen(!open)}></OverlayStyled>
  );
};

Overlay.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Overlay;
