import styled from "styled-components";

export const OverlayStyled = styled.div`
  display: ${props => (props.open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 50;
`;
