import styled, { css } from "styled-components";

export const SmallHeadingMixin = css`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.secondary ? "1.375rem" : "1.875rem")};
  line-height: ${(props) => (props.secondary ? "140%" : "117%")};
  margin-bottom: 1.625rem;
  text-align: ${(props) => (props.center ? "center" : "left")};
  color: #1c2f3e;
`;

export const Heading = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.h2 ? "1.875rem" : "3.75rem")};
  line-height: ${(props) => (props.h2 ? "217%" : "125%")};
  margin-bottom: 0.6rem;
  color: #1c2f3e;
  text-align: ${(props) => (props.h2 ? "center" : null)};
`;

export const SubHeading = styled.p`
  max-width: 70ch;
  margin: 0 auto;
  margin-bottom: 2.31rem;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 162%;
  text-align: center;
  letter-spacing: -0.3px;

  color: #7f7f7f;
`;

export const Heading3 = styled.h3`
  ${SmallHeadingMixin};
  max-width: 35ch;
  margin-left: auto;
  margin-right: auto;
  a {
    all: inherit;
    margin-bottom: 0;
  }
`;

export const TextMixin = css`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 175%;
  margin-bottom: 2.25rem;
  color: #5c5c5c;
  max-width: 70ch;
`;

export const Text = styled.p`
  ${TextMixin};
`;

export const Icon = styled.div`
  margin-bottom: 0.6rem;
`;
