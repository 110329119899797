import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "./MenuToggle.styled";

const MenuToggle = ({ open, setOpen }) => {
  let [iconAnimClass, setIconAnimclass] = useState("");
  function handleBtnClick() {
    if (!iconAnimClass) setIconAnimclass("play");
    setOpen(!open);
  }
  return (
    <Button
      type="button"
      onClick={handleBtnClick}
      aria-label="Toggle menu"
      aria-expanded={open}
    >
      <Icon className={iconAnimClass} open={open}></Icon>
    </Button>
  );
};

MenuToggle.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default MenuToggle;
