export const theme = {
  mainContainerWidth: "1172px",
  headerNavHeight: "4rem",
  footerHeight: "8rem",
  // COLORS
  primaryBg:
    "linear-gradient(190.25deg, #0CE4F3 -60%, #37AAEF 14.72%, #37AAEF 14.72%, #37AAEF 14.72%, #5386EC 81.06%, #952DE5 165.84%)",
  // FONT COLORS
  navMobile: "white",
  navColor: "#5c5c5c",
  primaryHover: "#343078",
  tabletScreen: "1100px",
  largeScreen: "1800px",
  sectionSpacing: "8.375rem",
};
