import styled from "styled-components";
import { Link } from "gatsby";

export const NavLinkContainer = styled.ul`
  position: fixed;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  right: 2rem;
  top: 1rem;
  width: 14rem;
  height: 18rem;
  border-radius: 10px;
  background: ${(props) => props.theme.primaryBg};
  clip-path: ${(props) =>
    props.open ? "circle(75%)" : "circle(2rem at 85% 12%)"};
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  z-index: 999;
  li {
    flex-grow: 1;
  }
  @media screen and (min-width: ${({ theme }) => theme.tabletScreen}) {
    position: static;
    padding: 0;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: none;
    background: transparent;
    clip-path: none;
    li:first-child {
      display: none;
    }
    li {
      height: 100%;
    }
  }
`;

export const NavItem = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  vertical-align: middle;
  padding: 0 2rem;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 200%;
  text-align: right;
  text-transform: uppercase;
  color: white;
  transition: all 0.3s;
  &.active {
    color: #000;
  }
  :hover {
    color: #000;
  }
  @media screen and (min-width: ${({ theme }) => theme.tabletScreen}) {
    color: ${({ theme }) => theme.navColor};
  }
`;

export const Toggle = styled.li`
  position: absolute;
  /* vals based on clip-path values */
  top: calc(12% - 2rem);
  left: calc(85% - 2rem);
  height: 4rem;
  width: 4rem;
`;
