import React from "react";
import { FooterStyled } from "./Footer.styled";
import { Logo } from "../Navigation/Navigation.styled";

const Footer = () => {
  return (
    <FooterStyled fullWidth as="footer" row="footer">
      <Logo as="p">Serbay</Logo>
      <p className="copyright">
        © Copyright {new Date().getFullYear()}&nbsp;<strong>Serbay</strong>
        &nbsp;Administrator{" "}
      </p>
    </FooterStyled>
  );
};

export default Footer;
